import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import MainDisplay from "../../service/live-display/MainDisplay";
import LiveCalender from "../../service/live-display/LiveCalender";
import VodMainList from "../../service/live-display/VodMainList";
import { userState } from "../../interface/MainInterface";
import Header from "../Header";
import { Button, CardMedia, Stack } from "@mui/material";

interface propsType {
  userState: userState;
  webViewStatus: string;
}

const LiveHome = (props: propsType) => {
  let navigate = useNavigate();
  const callBackLiveCalender = (command: any) => {};

  useEffect(() => {
    // navigate("/mngChannel/none/none/none");
  }, []);
  return (
    <>
      {/* <Header userState={props.userState} />
      <Box sx={{ width: "100%", flexGrow: 1, px: 5, pb: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MainDisplay />
          </Grid>
          <Grid item xs={12}>
            <LiveCalender
              webViewStatus={props.webViewStatus}
              callBack={callBackLiveCalender}
              userState={props.userState}
            />
          </Grid>
          <Grid item xs={12}>
            <VodMainList webViewStatus={props.webViewStatus} userState={props.userState} />
          </Grid>
        </Grid>
      </Box> */}
      <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#000000" }}>
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Stack direction={"column"} spacing={4} sx={{ alignItems: "center" }}>
            <CardMedia component={"img"} image="/images/logo.png" sx={{ width: "50%" }}></CardMedia>
            <Button
              variant="text"
              onClick={() => {
                window.location.href = "https://thd-admin.hd-smartstore.com/";
              }}
            >
              {"관리자 페이지 바로가기"}
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default LiveHome;
