import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { appConfig } from "../../../config/Config";
import { v4 as uuidv4 } from "uuid";
import { useInterval } from "../../../utilities/UseInterval";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { ListChildComponentProps, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { HttpClientApi, SendChannelMessageParams } from "../../../service/client-rest-api";
import { HttpChatApi } from "../../../service/chat-rest-api";
import { HttpChatInputApi } from "../../../service/chat-input-rest-api";
import { userState } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../service/common_utils";
import * as ChatConstant from "./ChatConstant";
import "./Chat.css";
import dayjs from "dayjs";

interface propsType {
  broad_seq: string;
  broad_status: string;
  channelArn: string;
  adminArn: string;
  chat_open: boolean;
  hidden_menu: boolean;
  chatInitCallback: any;
  userState: userState;
  chatFontColor: string;
  sendIoTMessage: any;
  noChat: boolean;
}

interface chatSendForm {
  msg: string;
}

interface autoLinkProp {
  text: String;
}

const chatApi = new HttpChatApi();
const chatInputApi = new HttpChatInputApi();

const ChatVerNoSdk = (props: propsType, ref: any) => {
  const ClientApi = new HttpClientApi();
  const cUtils = new CommonUtils();
  const chatTextSize: string = "13px"; //"14px";

  const chatInnerRef = useRef<HTMLDivElement>();
  const listRef = useRef<any>(null); // Ref for chatting list
  const rowHeights = useRef<any>({}); // Array for set dynamic height foreach message contents

  const [chatInit, setChatInit] = useState(false);
  const [messages, setMessages] = useState<any>([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [needScrollEnd, setNeedScrollEnd] = useState<Boolean>(true);
  const [btnNewMsgBoxStyle, setBtnNewMsgBoxStyle] = useState({
    display: "none",
  });
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [visibility, setVisibility] = useState("visible");
  const [blockList, setBlockList] = useState<any>([]);

  const [pool, setPool] = useState(false);

  // Ref for subscribe callback
  const messagesRef = useRef(messages);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setChatInit(false);
      setMessages([]);
      setNextToken(undefined);
      setNeedScrollEnd(true);
      setBtnNewMsgBoxStyle({ display: "none" });
      setVisibleStartIndex(0);
      setVisibility("visible");
      setBlockList([]);
      setPool(false);
    };
  }, []);

  // Form for send message to chime channel
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<chatSendForm>();

  useEffect(() => {
    if (props.noChat) {
      setVisibility((visibility) => "hidden");
      setBtnNewMsgBoxStyle({ display: "none" });
    } else {
      if (props.hidden_menu && !props.chat_open) {
        setVisibility((visibility) => "hidden");
        setBtnNewMsgBoxStyle({ display: "none" });
      } else {
        setVisibility((visibility) => "visible");
        setBtnNewMsgBoxStyle(needScrollEnd ? { display: "none" } : { display: "inline" });
      }
    }
  }, [props.hidden_menu, props.chat_open, props.noChat]);

  useEffect(() => {
    if (props.channelArn !== "") {
      getListChannelMessages(props.channelArn);
    }
  }, [props.channelArn]);

  const delayPool: number = 5000; // 5초마다 변경
  useInterval(
    () => {
      pollChannelMessages(props.channelArn);
    },
    pool ? delayPool : null
  );

  useEffect(() => {
    if (props.broad_seq !== "") {
      getBlockList(props.broad_seq);
    }
  }, [props.broad_seq]);

  // subscript callback 연동용 Ref 최신화
  useEffect(() => {
    messagesRef.current = messages;
  });

  // 채팅창 스크롤 제일 아래로 이벤트 처리
  useEffect(() => {
    if (messages.length > 0) {
      if (needScrollEnd) {
        setBtnNewMsgBoxStyle({ display: "none" });
        scrollToBottom();
      } else {
        if (!props.hidden_menu) setBtnNewMsgBoxStyle({ display: "inline" });
      }
    }
  }, [messages, needScrollEnd]);

  // 최초 채널 입장시 기존 채팅 메시지 조회
  const getListChannelMessages = async (channel_arn: string) => {
    const param: any = {
      channel_arn: channel_arn,
      next_token: nextToken,
      list_mode: appConfig.listChannelMode,
    };

    await chatApi.list_chat_history(param).then((result: any) => {
      if (result.code === "200") {
        let normalMessages = result.response.chat_history.Messages.filter(isNormalMsg); // 일반 메세지만 표시
        setMessages(normalMessages);
        // 안드로이드 삼성 브라우저에서 채팅 메세지 처음에 안보여서 이렇게 히든 후 비져블 처리함...
        setVisibility("hidden");
        setVisibility("visible");
        props.chatInitCallback(true);
        setNeedScrollEnd(true);
        setChatInit(true);
      }
    });
  };

  const pollChannelMessages = async (channel_arn: string) => {
    const param: any = {
      channel_arn: channel_arn,
      next_token: nextToken,
      list_mode: appConfig.listChannelMode,
      broad_seq: props.broad_seq,
      limit: 30,
    };

    await chatApi.list_chat_limit_history(param).then((result: any) => {
      if (result.code === "200") {
        let normalMessages = result.response.chat_history.Messages.filter(isNormalMsg); // 일반 메세지만 표시
        for (const msg of normalMessages) {
          msg.ChannelArn = channel_arn;
          processChannelMessage(msg);
        }
      }
    });
  };

  useEffect(() => {
    if (props.broad_status === "STOP" || props.broad_status === "CREATE" || props.broad_status === "VOD") {
      setPool(false);
    } else {
      if (chatInit) setPool(true);
      else setPool(false);
    }
  }, [props.broad_status, chatInit]);

  const isNormalMsg = (element: any) => {
    if (element.Metadata === "" || element.Metadata === undefined) {
      return true;
    } else if (
      // 채팅 금지 처리
      element.Metadata !== undefined &&
      element.Metadata === "chat_blocked" &&
      element.Sender.Arn === props.userState.userArn
    ) {
      return true;
    } else if (
      // 관리자가 보낸 당첨자 공지 (여기 Metadata 사용때문)
      element.Sender.Arn === props.adminArn &&
      element.Metadata !== "chat_blocked" &&
      element.Metadata !== undefined &&
      element.Metadata !== ""
    ) {
      return true;
    }
  };

  // 채팅 메시지 도착(실시간)시 처리용 프로세스
  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    if (newMessage.ChannelArn !== props.channelArn) return;
    let isDuplicate = false;
    messagesRef.current?.forEach((m: any, i: number, self: any) => {
      if ((m.response?.MessageId || m.MessageId) === newMessage.MessageId) {
        isDuplicate = true;
        self[i] = newMessage;
      }
    });

    let newMessages: any = [...messagesRef.current];
    if (!isDuplicate && newMessage.Persistence === ChatConstant.Persistence.PERSISTENT) {
      // 채팅 금지 처리
      if (!isBlocked(newMessage)) {
        newMessages = [...newMessages, newMessage];
        setMessages(newMessages);
      }
    }
    // 메세지 수정 처리
    if (isDuplicate && newMessage.Persistence === ChatConstant.Persistence.PERSISTENT) {
      setMessages(newMessages);
    }
  };

  // 채팅 금지 처리
  const isBlocked = (element: any) => {
    if (element.Metadata !== undefined && element.Metadata === "chat_blocked") {
      if (element.Sender.Arn !== props.userState.userArn) return true;
    }
  };

  // 채팅 메시지 전송
  const sendMessage = async (msg: string) => {
    const isBlock = checkBlock(props.userState.id);
    let metadata = "";
    if (isBlock) metadata = "chat_blocked";
    const topic = `live/${props.broad_seq}/payload`;
    const chatPayload = {
      topic: "chat/message",
      ChannelArn: props.channelArn,
      Content: msg,
      Metadata: metadata,
      CreatedTimestamp: "",
      LastUpdatedTimestamp: "",
      MessageId: uuidv4(),
      Persistence: "PERSISTENT",
      Redacted: false,
      Sender: {
        Arn: props.userState.id,
        Name: props.userState.nickName,
      },
      Status: {
        Value: "SENT",
      },
      Type: "STANDARD",
    };

    props.sendIoTMessage(topic, chatPayload);
    await chatInputApi.send_chat_history(chatPayload);
    setNeedScrollEnd(true);
    setBtnNewMsgBoxStyle({ display: "none" }); // 최신메세지 버튼
  };

  // 채팅창 ListItem의 높이를 동적 처리하기 위한 기능
  function getRowHeight(index: number) {
    return rowHeights.current[index] + 8 || 36;
  }

  // 각 row별 높이를 저장하기 위한 기능
  function setRowHeight(index: any, size: any) {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // 채팅 메시지별 Row 그리는 기능(동적 높이 구현을 위한 기능 포함)
  function RenderRow(listProps: ListChildComponentProps) {
    const { index, style } = listProps;
    const newStyle = {
      left: "0px",
      height: style.height,
      width: "100%",
    };

    const rowRef = useRef<any>({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);

    return (
      <ListItem className="pd-0-imp" style={visibleStartIndex > 0 ? style : newStyle} key={index}>
        <ListItemText
          primary={
            <Typography ref={rowRef} component="div" className="chat-list-body">
              <Box
                sx={
                  messages[index].Sender.Arn === props.adminArn
                    ? {
                        fontSize: chatTextSize,
                        fontWeight: "500", //"bold",
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        borderRadius: 2,
                        backgroundColor: "#00000030", //"transparent",
                        // border: "solid 1px",
                        // borderColor: props.chatFontColor,
                        pl: 1,
                        pr: 1,
                        pt: 1,
                        pb: 1,
                      }
                    : {
                        fontSize: chatTextSize,
                        fontWeight: "500", //"bold",
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        borderRadius: 2,
                        // pl: 1,
                        // pr: 1,
                      }
                }
              >
                <div>
                  <span
                    style={
                      messages[index].Sender.Arn === props.adminArn
                        ? {
                            color: "#F2FF5A", // 어드민, 노랑색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }
                        : messages[index].Sender.Arn === props.userState.userArn
                        ? {
                            color: "#80C9FF", // 본인, 파랑색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }
                        : {
                            color: props.chatFontColor, // 일반 회원(어드민 제외), 회색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            opacity: "0.8",
                            fontWeight: "bold",
                          }
                    }
                  >
                    {messages[index].Sender.Arn === props.adminArn
                      ? messages[index].Sender.Name
                      : cUtils.maskingChar(messages[index].Sender.Name)}
                  </span>
                  {messages[index].Content.split(appConfig.quote_separator).map((line: string, idx: number) => {
                    const repleLength = messages[index].Content.split(appConfig.quote_separator).length;
                    let styleCss: any = {
                      color: props.chatFontColor,
                      wordBreak: "break-all",
                      whiteSpace: "pre-line",
                    };
                    if (idx !== repleLength - 1) styleCss.color = "#ebeaea";
                    if (idx === 0 && messages[index].Sender.Arn === props.adminArn) {
                      return (
                        <span key={index} style={styleCss}>
                          <AutoLink text={line} />
                        </span>
                      );
                    } else if (idx === 0) {
                      return (
                        <span key={index} style={styleCss}>
                          {line}
                        </span>
                      );
                    } else {
                      // styleCss.color = "#59f4f4";
                      return (
                        <span key={index + "-" + idx} style={styleCss}>
                          <br />
                          {appConfig.quote_separator + " "}
                          <AutoLink text={line} />
                        </span>
                      );
                    }
                  })}
                </div>
              </Box>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  // 스크롤에 따라서 최신 메시지, 스크롤 자동 하단 등 처리를 위한 이벤트
  const evtItemRendered = (e: any) => {
    if (chatInnerRef.current) chatInnerRef.current.style.marginTop = "auto";
    setVisibleStartIndex((visibleStartIndex) => e?.visibleStartIndex);
    if (e?.visibleStopIndex > 0 && messages.length > 0) {
      if (e.visibleStopIndex >= messages.length - 1) {
        setNeedScrollEnd(true);
        setBtnNewMsgBoxStyle({ display: "none" });
      } else {
        setNeedScrollEnd(false);
      }
    }
  };

  // 채팅창 내용 업데이트시 스크롤을 제일 아래로
  const scrollToBottom = () => {
    if (messages.length > 0) {
      listRef?.current?.scrollToItem(messages.length, "end");
    }
  };

  const AutoLink = (prop: autoLinkProp) => {
    const delimiter = /(https?:\/\/[^\s]+)/g;
    return (
      <>
        {prop.text.split(delimiter).map((word, index) => {
          const match = word.match(delimiter);
          if (match) {
            const url = match[0];
            return (
              <a
                key={`a-t-k-${index}`}
                style={{ textDecoration: "none", color: "#FF376D " }}
                target="_blank"
                href={url.startsWith("http") ? url : `http://${url}`}
              >
                {url}
              </a>
            );
          }
          return word;
        })}
      </>
    );
  };

  const getBlockList = async (broadSeq: string) => {
    const param: any = {
      broad_seq: broadSeq,
    };
    const res = await chatApi.get_block_list(param);
    if (res.code === "200") {
      let newBlockList: any = [];
      for (const blockInfo of res.response.block_list) {
        newBlockList.push({
          blockUserArn: blockInfo.sk,
          blockTime: blockInfo.block_time_stamp,
        });
      }
      setBlockList(newBlockList);
    }
  };

  const addBlockList = (blockInfo: any) => {
    let newBlockList: any = [];
    let dupIndex = -1;
    for (let i = 0; i < blockList.length; i++) {
      if (blockList[i].blockUserArn === blockInfo.blockUserArn) {
        dupIndex = i;
      }
    }
    if (dupIndex > -1) {
      newBlockList = blockList;
      newBlockList[dupIndex] = blockInfo;
    } else {
      newBlockList = [...blockList, blockInfo];
    }

    setBlockList(newBlockList);
  };

  const checkBlock = (userId: string) => {
    let isBlock: boolean = false;
    for (const blockInfo of blockList) {
      if (userId === blockInfo.blockUserArn) {
        // 현재시간
        const now: any = parseInt(dayjs().format("YYYYMMDDHHmmss"));
        const blockTime = parseInt(dayjs(blockInfo.blockTime).format("YYYYMMDDHHmmss"));
        if (now < blockTime) {
          isBlock = true;
          break;
        } else {
          isBlock = false;
        }
      } else {
        isBlock = false;
      }
    }
    return isBlock;
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    sendMessage,
    scrollToBottom,
    processChannelMessage,
    addBlockList,
  }));

  return (
    <>
      <Box
        id="thd-chat-root"
        component="div"
        sx={
          /* 메인화면에서 비디오영역 클릭시 메뉴 감추기 */
          {
            // width: "90%",
            height: "30vh",
            visibility: visibility,
          }
        }
        className="thd-chat-root"
      >
        <>
          {/* 체팅창 영역 */}
          <Box
            id="chat-box"
            component="div"
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
            className={visibleStartIndex > 0 ? "thd-gradient-box" : ""}
          >
            <Box sx={{ height: "100%", width: "100%" }}>
              <AutoSizer style={{ height: "100%", width: "100%" }}>
                {({ height, width }) => (
                  <VariableSizeList
                    height={height}
                    width={width}
                    itemSize={getRowHeight}
                    itemCount={messages.length}
                    overscanCount={5}
                    className="thd-chat-list"
                    innerRef={chatInnerRef}
                    ref={listRef}
                    onItemsRendered={evtItemRendered}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </Box>
            {/* 체팅창 스크롤 위로갈때 스크롤 아래로 이동하는 버튼 */}
            <Box className="btn-new-chat-hd" sx={btnNewMsgBoxStyle}>
              <Button
                sx={{
                  backgroundColor: "#ff376d",
                  "&:hover": { backgroundColor: "#ff376d" },
                  borderRadius: "20px",
                }}
                variant="contained"
                size="small"
                onClick={scrollToBottom}
                endIcon={<ArrowCircleDownIcon />}
              >
                <span>최신 채팅으로 이동</span>
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default forwardRef(ChatVerNoSdk);
